<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="500"
    >
        <v-card
            class="pa-4"
            
        >
            <v-card-title>
                Set Current Term
            </v-card-title>
            <v-card-text>
                <v-select
                    label="Term"
                    :items="terms"
                    v-model="term"
                    prepend-icon="mdi-numeric-1-box-multiple-outline"
                ></v-select>
                <date-picker                    
                    v-bind:label="labelTermStartDate"
                    v-bind:initialDate="dateTermStart"                    
                ></date-picker>

                <date-picker                    
                    v-bind:label="labelTermEndDate"
                    v-bind:initialDate="dateTermEnd"
                ></date-picker>

                <date-picker                    
                    v-bind:label="labelNextTermStartDate"
                    v-bind:initialDate="dateNextTermStart"
                ></date-picker>

                <v-text-field
                    label="Total Number of Sessions"
                    prepend-icon="mdi-counter"
                    v-model="totalSessions"
                ></v-text-field>

            </v-card-text>
            <v-card-actions>
                <span
                    v-show="saved"
                    class="green--text"
                >
                   Term Set Successfully
                    <v-icon
                        small
                        color="green"                                        
                        class="ml-3"
                    >
                        mdi-check-all
                    </v-icon>
                </span>

                <span
                    v-show="error"
                    class="red--text"
                >
                    {{ errorMessage }}
                    <v-icon
                        small
                        color="red"
                        class="ml-3"
                    >
                        mdi-alert-circle
                    </v-icon>
                </span>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="validate"
                >
                    Set Term
                </v-btn>
                <v-btn
                    text
                    outlined
                    @click="close"
                >
                    Close
                </v-btn>
            </v-card-actions>
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>
        </v-card>

    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DatePicker from './DatePicker';

export default {
    props: {
        dialogOpen: Boolean,
    },

    components: {
        DatePicker,
    },

    created: function () {
        this.initialize();
    },

    data: function () {
        return {
            dateTermStart: {date: null},
            dateTermEnd: {date: null},
            dateNextTermStart: {date: null},
            labelTermStartDate: 'Current Term Start',
            labelTermEndDate: 'Current Term End',
            labelNextTermStartDate: 'New Term Beginning',
            totalSessions: null,
            term: null,
            terms: [1,2,3],
            saved: false,
            error: false,
            errorMessage: null,
            overlay: false,
            errorMessages: '',
            dialog: this.dialogOpen, 
        }        
    },    

    
    methods: {
        ...mapMutations({
            setDialog: 'settings/setDialogSetTerm',
            setSelectedItem: 'settings/setSelectedItem',
            setCurrentTerm: 'settings/setCurrentTerm',
            setTermRegistration: 'settings/setTermRegistrationData',
            setAcademicTermId: 'app/setAcademicTermId',
            setAcademicYearId: 'app/setAcademicYearId',
            setAppTerm: 'app/setTerm',
            setCurrentAcademicPeriod: 'app/setCurrentAcademicPeriod',
        }),
        
        ...mapActions({
            getCurrentTerm: 'settings/getCurrentTerm',
            getNextTerm: 'settings/getNextTerm',
            postCurrentTerm: 'settings/postCurrentTerm',
            postTermRegistration: 'settings/postTermRegistration',
        }),

        async initialize () {
            console.log('initializing set term');
            let response = null;
            try {
                const currentTermPromise = this.getCurrentTerm();
                const nextTermPromise = this.getNextTerm();

                response = await currentTermPromise;
                this.dateTermStart.date = response.data.term_start;
                this.dateTermEnd.date = response.data.term_end;
                this.term = response.data.term;
                this.totalSessions = response.data.total_sessions;               

                response = await nextTermPromise;                
                this.dateNextTermStart.date = response.data.term_start;
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        validate () {
            this.overlay = true;
            if(this.dateTermStart.date && this.dateTermEnd.date && this.dateNextTermStart.date){
                this.setTerm();
            }
            else{
                this.errorMessage = "Information Missing."
                this.error = true;
                this.overlay = false;
            }
        },

        async setTerm () {
            let response = null;
            this.saved = false;
            this.error = false;
            this.errorMessage = null;
            

            this.setCurrentTerm({
                term: this.term,
                term_start: this.dateTermStart.date,
                term_end: this.dateTermEnd.date,
                total_sessions: this.totalSessions,
                next_term_start: this.dateNextTermStart.date
            });

            console.log(this.totalSessions);

           

            this.setTermRegistration({
                new_term_beginning: this.dateNextTermStart.date,
                total_sessions: this.totalSessions
            })

            try {
                const postCurrentTermPromise = this.postCurrentTerm();
                const postTermRegistration = this.postTermRegistration();

                response = await postCurrentTermPromise;                
                console.log(response.status);

                response = await postTermRegistration;
                console.log(response);

                this.updateAppTermSettings();

                this.saved = true;
            } catch (error) {
                if(error.response) console.log(error.response);
                this.errorMessage = 'An Error has occured';
                this.error = true;
            }
            this.overlay = false;
        },

        async updateAppTermSettings(){
            let response = null, academic_year_id = null, year = null;
            try {
                response = await this.getCurrentTerm();
                academic_year_id = response.data.academic_year_id;
                this.setAcademicTermId(response.data.id);
                this.setAcademicYearId(academic_year_id);
                this.setAppTerm(response.data.term);
                year = academic_year_id.toString().substr(0, 4) + '-' + academic_year_id.toString().substr(4);
                this.setCurrentAcademicPeriod(`Term: ${ response.data.term } Year: ${ year}`); 
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        close () {            
            this.$emit('dialog-close');
        },


    }

}
</script>