<template>
    <v-btn        
        text
        v-on:click="logOut"
        color="primary"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            primaryColor: 'app/getPrimaryColor',
        })
        
    },
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'app/setAppOverlay',
            setWelcomeMessage: 'mainMenu/setWelcomeMessage',
            setMarkSheetLoading: 'markSheet/setMarkSheetLoading', 
            setMainMenu: 'mainMenu/setMainMenu', 
            setMarkSheet: 'mainMenu/setMarkSheet',
            setTermReports: 'mainMenu/setTermReports',
            setPrintViewReports: 'mainMenu/setReports',
            setEmployees: 'mainMenu/setEmployees',
            setStudentSubjects: 'mainMenu/setStudentSubjects',
            setEditViewStudents: 'mainMenu/setEditViewStudents',
            setResetPassword: 'mainMenu/setResetPassword', 
            setStep: 'auth/setStep',
        }),

        async logOut () {            
            this.setOverlay(true);
            this.setWelcomeMessage(true);
            this.setMarkSheetLoading(false);
            this.setMainMenu(true);
            this.setMarkSheet(false);
            this.setTermReports(false);
            this.setPrintViewReports(false);
            this.setEmployees(false);
            this.setStudentSubjects(false);
            this.setEditViewStudents(false);
            this.setResetPassword(false);
            this.setStep(1);
            
            try {
                await this.signOut();
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }            
            
            this.setOverlay(false);
            //console.log("logout complete");
            this.$router.replace('/');
        }        
    }
}
</script>