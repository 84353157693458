import axios from 'axios'
export default{
    namespaced: true,
    state: {
        selectedItem: { item: -1},
        deletedStudent: '',
        dialogSetTerm: false,
        currentTerm: null,
        termRegistrationData: null, 
        editedSubject: {},        
    },

    getters: {
        getSelectedItem (state) {
            return state.selectedItem
        },
       
        getDeletedStudent(state){
           return state.deletedStudent
        },

        getDialogSetTerm (state) {
            return state.dialogSetTerm
        },

        getCurrentTerm (state) {
            return state.currentTerm
        },

        getTermRegistrationData (state) {
           return state.termRegistrationData
        },

        getEditedSubject (state) {
            return state.editedSubject
        }
    },

    mutations: {
        setSelectedItem (state, value) {
            state.selectedItem = value
        },

        setDeletedStudent(state, value){
            state.deletedStudent = value
        },

        setDialogSetTerm (state, value) {
            state.dialogSetTerm = value
        },

        setCurrentTerm (state, value) {
            state.currentTerm = value
        },

        setTermRegistrationData (state, value) {
            state.termRegistrationData = value
        },

        setEditedSubject (state, value) {
            state.editedSubject = value
        }
    },

    actions: {
        getTermRegistrations(){
            let url = '/api/term-registration';
            return axios.get(url);
        },

        deleteTermRegistration({ getters }){
            let url = '/api/term-registration';
            return axios.delete(url, {
                data: getters.getDeletedStudent
            })
        },

        getCurrentTerm () {
            let url = '/api/current-term';
            return axios.get(url);
        },

        getNextTerm () {
            let url = '/api/next-term';
            return axios.get(url);
        },

        postCurrentTerm ({ getters }) {
            let url = '/api/current-term';
            //console.log(getters.getCurrentTerm);
            return axios.post(url, getters.getCurrentTerm);
        },

        postTermRegistration ({ getters }) {
            let url = '/api/term-registration';
            console.log(getters.getTermRegistrationData);
            return axios.post(url, getters.getTermRegistrationData);
        },

        getSubjects () {
            const url = '/api/subjects';
            return axios.get(url);
        },

        postSubject ({ getters }) {
            const url = '/api/subjects';
            return axios.post(url, getters.getEditedSubject);
        },

        deleteSubject ({ getters }) {
            const url = '/api/subject';            
            return axios.delete(url, { data: { id: getters.getEditedSubject.id }})
        },

        promoteStudents () {
            const url = '/api/promote';
            return axios.post(url);
        }
    }
}